<template>
  <div
    class="my-btn-tag"
    :class="[{ active: active }, type === 'default' ? '' : type]"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "BtnTag",
  props: {
    active: Boolean,
    type: String
  }
};
</script>

<style lang="scss" scoped>
.my-btn-tag {
  border: 1px solid #1989fa;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  line-height: 1;
  font-size: 13px;
  color: #1989fa;
  border-radius: 2px;
  &.active {
    color: #fff;
    background: #1989fa;
  }
}
.my-btn-tag.danger {
  border-color: #ee0a24;
  color: #ee0a24;
  &.active {
    color: #fff;
    background: #ee0a24;
  }
}
</style>